import React from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../layout/layout";
import config from "../../data/SiteConfig";
import Img from "gatsby-image";
import { Container, Content, Section, BreadcrumbItem, Breadcrumb } from "bloomer";

export default class ProjectTemplate extends React.Component {
  render() {
    const project = this.props.data.markdownRemark;

    return (
      <Layout>
        <Helmet>
          <title>{`${project.frontmatter.title} | ${config.siteTitle}`}</title>
        </Helmet>
        <Section className="has-navbar-padding">
          <Container>
            <hr />
            <Breadcrumb>
              <ul>
                <BreadcrumbItem>
                  <Link to="/">Home</Link>
                </BreadcrumbItem>
                <BreadcrumbItem isActive>
                  <Link to={project.fields.slug}>
                    {project.frontmatter.title}
                  </Link>
                </BreadcrumbItem>
              </ul>
            </Breadcrumb>
            <h1 class="title is-1">{project.frontmatter.title}</h1>
            <hr />
            {project.frontmatter.cover ? (
              <Img
                style={{ marginBottom: "1.5rem" }}
                fluid={project.frontmatter.cover.childImageSharp.fluid}
              />
            ) : null}
            <Content
              renderAs="article"
              dangerouslySetInnerHTML={{ __html: project.html }}
            />
          </Container>
        </Section>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      fields {
          slug
      }
      frontmatter {
          permalink
          title
          cover {
            childImageSharp {
              fluid(maxWidth: 1344, maxHeight:340) {
                ...GatsbyImageSharpFluid
              }
            }
          }
      }
    }
  }
`;
